<template>
  <el-main>
    <SmallCard :info="smallInfo" @clickFn="openGrantDialog"></SmallCard>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="来源：">
        <el-select size="small" v-model="ruleForm.source" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="会员卡赠送" :value="1"></el-option>
          <el-option label="组局赠送" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否领取：">
        <el-select size="small" v-model="ruleForm.is_get" placeholder="请选择">
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="已领取" :value="1"></el-option>
          <el-option label="未领取" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发放时间：">
        <el-date-picker v-model="ruleForm.time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="21px">
        <el-button size="small" type="primary" @click="getTicketList()">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="ruleForm.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
      <el-table-column prop="type" label="门票来源" width="100" align="center">
        <template v-slot="{ row }">
          <span>{{ getSource(row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invite_code" width="200" label="门票码" align="center"></el-table-column>
      <el-table-column label="门票状态" width="100" align="center">
        <template v-slot="{ row }">{{ getStatusName(row) }}</template>
      </el-table-column>
      <el-table-column label="领取状态" width="100" align="center">
        <template v-slot="{ row }">{{ row.user_id ? '已领取' : '未领取' }}</template>
      </el-table-column>
      <el-table-column label="发放时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="过期时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.end_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" size="small" v-if="!row.deleted" @click="loseActivity(row)">失效</el-button>
          <el-button type="text" size="small" v-else>已失效</el-button>
          <!-- <el-button type="text" size="small">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="ruleForm.total" :page="ruleForm.page" :pageNum="ruleForm.rows" @updatePageNum="updateData"></Paging>
    <!-- 添加门票弹窗 -->
    <el-dialog title="添加门票" :visible.sync="showAddDialog" width="600rpx">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="发放组局：">
          <el-select style="width: 100%" v-more="loadMoreType" v-model="bindingZujuID" filterable remote :remote-method="getChangeList" placeholder="请选择" class="classify_select">
            <el-option style="width: 500px" v-for="item in change_info.list" :key="item.id" :label="item.zuju_title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发放数量：">
          <el-input style="width: 220px" v-model="change_info.num" placeholder="请输入发放数量："></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="sureGrant">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
import SmallCard from '@/components/smallCardBox.vue';
export default {
  components: {
    Paging,
    SmallCard,
  },
  data() {
    return {
      getDateformat,
      smallInfo: {
        img: require('@/assets/smallCardImgs/3.png'),
        title: '门票',
        desc: '活动必备神器',
        btnTxt: '添加门票',
      },
      ruleForm: {
        page: 1,
        rows: 10,
        source: 0,
        is_get: -1,
        time: [],
        total: 0,
        list: [],
      },
      showAddDialog: false,
      bindingZujuID: null,
      change_info: {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
        name: '',
        num: '',
      },
    };
  },
  created() {
    this.getTicketList();
    this.getChangeList();
  },
  // 自定义指令
  directives: {
    more: {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.classify_select .el-scrollbar .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight + 50;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  methods: {
    loseActivity(data) {
      this.$confirm('此操作将失效该门票码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.yiyun.loseActivity, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('操作成功');
            this.getTicketList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    sureGrant() {
      if (!this.bindingZujuID) {
        this.$message.warning('请选择发放组局');
        return;
      }
      if (!this.change_info.num) {
        this.$message.warning('请填写发放数量');
        return;
      }
      let obj = {
        zuju_id: this.bindingZujuID,
        num: this.change_info.num,
      };
      this.$axios.post(this.$api.yiyun.sendInvodeCode, obj).then(res => {
        if (res.code == 0) {
          this.$message.success('发放成功');
          this.getTicketList();
          this.showAddDialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    openGrantDialog() {
      this.change_info = {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
        name: '',
        num: '',
      };
      this.showAddDialog = true;
    },
    // 服务分类下拉框 滑动加载更多数据
    loadMoreType() {
      console.log(this.change_info.total / this.change_info.rows, this.change_info.page);
      console.log(this.change_info.total, this.change_info.rows, this.change_info.page);
      if (Math.ceil(this.change_info.total / this.change_info.rows) > this.change_info.page) {
        console.log(11111111111);
        this.change_info.page++;
        this.getChangeList(this.change_info.name, 1);
      }
    },
    // 获取组局列表
    getChangeList(name, is_more) {
      if (!is_more) {
        this.change_info.page = 1;
      }
      this.change_info.name = name;
      this.$axios
        .post(this.$api.yiyun.ZuJuList, {
          page: this.change_info.page,
          rows: this.change_info.rows,
          status: 1,
          keywords: name,
        })
        .then(res => {
          if (res.code === 0) {
            if (is_more) {
              this.change_info.list = this.change_info.list.concat(res.result.list);
            } else {
              this.change_info.list = res.result.list;
            }
            this.change_info.total = res.result.total_number;
          }
        });
    },
    getSource(data) {
      if (data.zuju_id > 0) return '组局';
      if (data.card_id > 0) return '会员卡';
      return '后台发放';
    },
    getStatusName(data) {
      let nowTime = new Date().getTime() / 1000;
      if (data.status == 0) return '已使用';
      if (nowTime >= data.end_time) return '已过期';
      if (data.status == 1) return '未使用';
      return '--';
    },
    clearSearch() {
      this.ruleForm = {
        page: 1,
        rows: 10,
        source: 0,
        is_get: -1,
        time: [],
        total: 0,
        list: [],
      };
      this.getTicketList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.ruleForm.rows = val;
      } else {
        this.ruleForm.page = val;
      }
      this.getTicketList();
    },
    getTicketList() {
      let obj = {
        page: this.ruleForm.page,
        rows: this.ruleForm.rows,
      };
      if (this.ruleForm.source) obj.comefrom = this.ruleForm.source;
      if (this.ruleForm.is_get != -1) obj.is_get = this.ruleForm.is_get;
      if (this.ruleForm.time?.length) {
        obj.create_time = [];
        obj.create_time[0] = Math.ceil(this.ruleForm.time[0].getTime() / 1000);
        obj.create_time[1] = Math.ceil(this.ruleForm.time[1].getTime() / 1000);
        if (obj.create_time[0] == obj.create_time[1]) {
          obj.create_time[1] += 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.yiyun.invideCodeList, obj).then(res => {
        if (res.code == 0) {
          this.ruleForm.total = res.result.total_number;
          this.ruleForm.list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
